.logoutBtn:hover {
  background-color: #518ff5 !important;
  color: white;
}

.blockBtn:hover {
  background-color: white !important;
  color: green;
}

.deletedBtn {
  color: red;
}

.viewBtn {
  color: #518ff5;
}

.submitBtn {
  background-color: #518ff5 !important;
  color: white;
}

.deleteModalBtn {
  background-color: red !important;
}

.deleteModalBtn:hover {
  background-color: white !important;
  color: red !important;
}

.noBtn:hover {
  background-color: white !important;
  color: green !important;
}

.noBtn {
  background-color: green !important;
}

.modalStyle {
  height: 300 !important;
  overflow: "auto" !important;
}
